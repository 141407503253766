import React from 'react';
import './App.css';  // Import custom styles
import ParallaxContainer from './ParallaxContainer';
import ZoomOnScroll from './ZoomOnScroll';
import game1 from './images/Game1.png';
import game2 from './images/Game2.png';
import game3 from './images/Game3.png';
import game4 from './images/Game4.png';
import earlyaccess from './images/EarlyAccess.png';
import telegramIcon from './images/telegram.png';
import iphoneImage from './images/phone1.png';
import iphoneImage2 from './images/phone2.png';
import iphoneImage3 from './images/phone3.png';
import iphoneImage4 from './images/phone4.png';
import iphoneImage5 from './images/phone5.png';
import iphoneImage6 from './images/phone6.png';
import tvPhone from './images/tvphone.png';
import fanarcade from './images/FanArcade.png';
import slider from './images/slider.png';
import coins1 from './images/coins.png';
import coins2 from './images/coins2.png';
import coinsandgems from './images/coinandgems.png';
import bringIt from './images/bringit.png';
import fanlogo from './images/fanlogo.png';
import ctaButtonSvg from './images/ctaButton_responsive.svg';
import ctaButtonSvg2 from './images/ctaButton_responsive3.svg';
import TopLeftMenu from './TopLeftMenu';


function App() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className="App">
    <TopLeftMenu /> {/* Add the menu here */}
      <header className="hero">
        <div className="hero-content">
          {/* h2 above the game images */}
          <h2>IT'S</h2>
          {/* Grouped images */}
          <div className="game-images">
            <img src={game4} alt="Game 4" className="game-image game4" />
            <img src={game3} alt="Game 3" className="game-image game3" />
            <img src={game2} alt="Game 2" className="game-image game2" />
            <img src={game1} alt="Game 1" className="game-image game1" />
          </div>

          {/* h1 below the game images */}
          <h1>TIME</h1>
          {/* Button with image */}
          <a href="https://t.me/fan_arcade_bot" target="_blank" rel="noopener noreferrer">
            <button className="hero-button">
              <img src={ctaButtonSvg} alt="CTA Button" />
            </button>
          </a>        
         
          {/* Text below the button */}
          <p className="telegram-text">PLAY NOW ON TELEGRAM</p>
        </div>
     </header>

      {/* Bottom image */}
      <div className="bottom-image-container">
        <img src={iphoneImage} alt="iPhone" className="bottom-image" />
      </div>
        {/* New section for additional contents */}
<div className="new-section">
<section id="about">
      <div style={{ height: '10vh' }}></div>
      <ParallaxContainer className="responsive-container" direction="up">
  <div className="iphone-image-container-left">
    <img src={iphoneImage2} alt="iPhone" className="responsive-image" />
  </div>
  <div className="text-container-right">
    <h3>WELCOME TO FAN.io</h3>
    <h4>LIVE SPORTS GAMING REDEFINED</h4>
    <p>FAN.io is the ultimate sports arcade for fans to play real-time interactive games. Ready to take your fandom up a notch? Join us on Telegram now!</p>
  </div>
</ParallaxContainer>

<div style={{ height: '5vh' }}></div>
      <ParallaxContainer className="responsive-container" direction="up">
      <div className="text-container-left">
    <h3>GET IN THE GAME</h3>
    <h4 style={{ color: '#FF781F'}}>EXPERIENCE LIVE SPORTS LIKE NEVER BEFORE</h4>
    <p>Pick any live game and play alongside your favorite teams while the action unfolds!</p>
  </div>
  <div className="iphone-image-container-right">
    <img src={iphoneImage3} alt="iPhone" className="responsive-image" />
  </div>
</ParallaxContainer>
</section>

<section id="playmaker">
<div style={{ height: '5vh' }}></div>
<ParallaxContainer className="responsive-container" direction="up">
      <div className="text-container-left" >
    <img src={fanarcade} alt="Fans" className="responsive-image" />
  </div>
  <div className="iphone-image-container-right">
    <img src={slider} alt="Slider" className="responsive-image" />
  </div>
</ParallaxContainer>

<div style={{ height: '5vh' }}></div>
<ParallaxContainer className="responsive-container" direction="up">
<div className="iphone-image-container-left">
    <img src={tvPhone} alt="television" className="responsive-image" />
  </div>
      <div className="text-container-right" >
    <h3>PLAYMAKER for NBA</h3>
    <h4>YOU CALL THE SHOTS</h4>
    <p>PLAYMAKER puts you in control of the action. Play alongside live NBA broadcasts and call the shots for every pass, every dunk, and every buzzer-beater as they happen!</p>
  </div>
 </ParallaxContainer>

<div style={{ height: '4vh' }}></div>
<ParallaxContainer className="responsive-container" direction="up">
<div className="text-container-left" >
    <h3>WIN BIG</h3>
    <h4 style={{ color: '#FF781F'}}>PLAY TO EARN REAL PRIZES</h4>
    <p>Every game you play earns you rewards in real-time. No extra steps. No waiting. Just jump in, play, and watch the rewards roll in!</p>
  </div>
<div className="iphone-image-container-right">
    <img src={iphoneImage6} alt="iPhone" className="responsive-image" />
  </div>
</ParallaxContainer>
</section>

<div style={{ height: '10vh' }}></div>
<ParallaxContainer className="responsive-container" direction="up">
    <img src={bringIt} alt="Banner" className="parallax-banner-image"/>
</ParallaxContainer>

<div style={{ height: '20vh' }}></div>
<section id="rewards">
<ParallaxContainer className="responsive-container" direction="up">
<div className="iphone-image-container-left">
    <img src={coins1} alt="coins" className="responsive-image" style={{ margin: '-30px'}}/>
  </div>
      <div className="text-container-right" style={{ margin: '20px'}}>
    <img src={earlyaccess} alt="Early" style={{ width: '100%' }}/>
  </div>
</ParallaxContainer>

<div style={{ height: '20vh' }}></div>
<ParallaxContainer className="responsive-container" direction="up">
      <div className="text-container-left" >
    <h3 style={{ color: 'white' }}>JOIN NOW, WIN BIG!</h3>
    <h4 style={{ color: '#FF781F'}}>ONLY AVAILABLE FOR EARLY-ACCESS FANS</h4>
    <p>Every week, we're offering exclusive rewards just for our early adopters! These loot boxes are packed with surprises—think airdrops, tickets, gift cards, and more. </p>
  </div>
  <div className="iphone-image-container-right">
    <img src={iphoneImage4} alt="iPhone" className="responsive-image" />
  </div>
</ParallaxContainer>
<div style={{ height: '10vh' }}></div>
 </section>

<section id="coming-soon">
<ParallaxContainer className="responsive-container" direction="up">
  <div className="iphone-image-container-left">
    <img src={iphoneImage5} alt="iPhone" className="responsive-image" />
  </div>
  <div className="text-container-right">
  <h3>STACK UP REWARDS</h3>
    <h4>ALL SEASON LONG</h4>
    <p>The more you play, the more you earn! Level up, unlock exclusive rewards, and grow your collection with every game. Season 0 won’t last forever—so make it count!</p>
  </div>
</ParallaxContainer>

<div style={{ height: '10vh' }}></div>
<ParallaxContainer className="responsive-container" direction="up">
      <div className="text-container-left" >
    <img src={coinsandgems} alt="iPhone" className="responsive-image" />
      </div>
  <div className="text-container-right">
    <h3>WE’RE JUST WARMING UP</h3>
    <h4 style={{ color: '#FF781F'}}>NEW FEATURES YOU’LL LOVE</h4>
    <p>Get ready for exciting new features, including global leaderboards, real-time PvP, new sports games, and much more. Stay updated on all our latest releases right here—don’t miss out!</p>
  </div>
</ParallaxContainer>

<div style={{ height: '8vh' }}></div>

<ParallaxContainer className="responsive-container" direction="up">
      <div className="text-container-left" >
      <h4 style={{color: 'white'}}>KEEP UP TO DATE ON OUR LATEST RELEASES <a href="https://trello.com/invite/b/6717d98fcf1af7c0eff6df03/ATTIa10e913755405f8d780b96e34ca05c3104416BAD/fanio-community-dashboard" target="_blank" rel="noopener noreferrer">HERE</a></h4>
      </div>
  <div className="iphone-image-container-right" style={{margin: '10px'}}>
    <img src={coins2} alt="coins2" className="responsive-image" />
  </div>
</ParallaxContainer>
</section>

<div style={{ height: '40vh' }}></div>
<ParallaxContainer direction="up">
<div style={{ height: '30vh' }}></div>
<div className="text-center">
<h2>IT'S</h2>
    <h2 style={{color: '#00FF8D'}}>GAME</h2>
    <h1>TIME</h1>
  </div>
  <div style={{ height: '3vh' }}></div>
  <div className="text-center">
      <a href="https://t.me/fan_arcade_bot" target="_blank" rel="noopener noreferrer">
            <button style={{  background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    outline: 'none'}}>
              <img src={ctaButtonSvg2} alt="CTA Button" />
            </button>
          </a>
          </div>
          <div style={{ height: '5vh' }}></div>
          <div className="text-center">
<img src={fanlogo} alt="fan" className="responsive-image" style={{ width: '30%' }} />
</div>
<div style={{ height: '10vh' }}></div>
</ParallaxContainer>

<button
        className="scroll-to-top"
        onClick={scrollToTop}
        aria-label="Scroll to top"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="chevron-icon"
        >
          <polyline points="18 15 12 9 6 15" />
        </svg>
      </button>
    {/* Footer Section */}
      <footer className="footer">
        <div className="footer-section">
          <h4>Legal</h4>
          <ul>
            <li><a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=4e7d8f38-0fdc-41fe-85a7-43aba1b783e4" target="_blank" rel="noopener noreferrer">Terms and Conditions</a></li>
            <li><a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=6d7b0450-90e8-4aa5-9f1f-e07c930a5bc4" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Follow Us</h4>
          <ul>
            <li><a href="https://x.com/fan_io" target="_blank" rel="noopener noreferrer">Twitter</a></li>
            <li><a href="https://www.linkedin.com/company/fan-io/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
          </ul>
        </div>
      </footer>
      </div>
    </div>
  );
}

export default App;
